import * as React from "react"
import CallToAction from "../components/block/call-to-action"
import About from "../components/block/about"
import Footer from "../components/block/footer"
import Header from "../components/block/header"
import Services from "../components/block/services"
import NavBar from "../components/navbar"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

const pageData = {
  pageTitle: 'Villanyszerelési munkák teljeskörű kivitelezése',
  pagePromotionText: ['Budapest, Pest megye vonzáskörzetében'],
}

export const query = graphql`
  query {
    file(name: {eq: "header_landing"}) {
      id
      base
      publicURL
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          quality: 40,
          blurredOptions: {toFormat: WEBP},
          formats: [WEBP]
        )
      }
    }
  }
`

// markup
const IndexPage = ({data}: any) => {
  return <>
    <SEO title='Villanyszerelési munkák teljeskörű kivitelezése' 
        image={getImage(data.file)?.images.fallback?.src}></SEO>
    <NavBar></NavBar>
    <Header 
      pageTitle={pageData.pageTitle}
      pageDescription={pageData.pagePromotionText}
      fullSizeHeader={false}
      image={data.file}
      />
    <main>
      {/* {<Services/>} */}
      <About/>
      {/* <CallToAction/> */}
    </main>

    <Footer/>

  </>
}

export default IndexPage
