import { Link } from 'gatsby'
import * as React from 'react'
import * as footerStyles from './footer.module.scss'
import Facebook from '../../assets/svg/facebook.svg';
import FooterLogo from '../../assets/svg/footer_logo_align_left.svg';
import Layout from '../layout';
import Obfuscate from 'react-obfuscate';


export function decryptEmail(encoded: any) {

    var address = atob(encoded);
    window.location.href = "mailto:" + address;

}


export default function Footer(): JSX.Element {

    return <Layout fullWidthBackground={true} additionalBackgroundClasses={footerStyles.simpleOrangeBackgroundFooterMargin}>
        <footer>
          <div className={footerStyles.footerBodyContainer}>
            <div>
                <h5>Ahol elérsz minket</h5>

                {/* <Link to='mailto:villanyszereles@dawatt.hu?subject=Kapcsolat%20felvétel'><p className={footerStyles.email}>villanyszereles@dawatt.hu</p></Link> */}
                {/* <Link to='tel:+36 30 410 3885'><p className={footerStyles.phone}></p></Link> */}

                <p className={footerStyles.email}>
                    <Obfuscate 
                        email="machacsdavidaron@gmail.com" 
                        headers={{
                            subject: 'Kapcsolat felvétel'
                        }}
                    />
                </p>

                <p className={footerStyles.phone}>
                    <Obfuscate tel="+36 30 410 3885" />
                </p>

                <div className={footerStyles.logoFacebookContainer}>
                    <a href='https://www.facebook.com/profile.php?id=100082717953603' target="_blank" rel="noopener noreferrer"><img src={Facebook} alt="Dawatt villanyszereles piliscsaba facebook"/></a>
                    <img width={188} height={43} src={FooterLogo} alt="Dawatt villanyszereles piliscsaba"/>
                </div>

                <p className={footerStyles.companyDetails}>
                    Dawatt Electrics Kft.
                    <br/>
                    32101052-2-13
                </p>
            </div>


            <div id='staticContact' className={footerStyles.footerLeft}>
                {/* <h6>Ezek is érdekelhetnek</h6> */}

                {/* <Link to="/">főoldal</Link> */}
                {/* <Link to="/elektromos-tervezes-merohely-kialakitas-villamharito/">Elektromos tervezés, Mérőhely kialakítás, Villámhárító</Link>
                <Link to="/hagyomanyos-villanyszereles-intelligens-otthon/">Villamoshálózat kiépítése, felújítása</Link>
                <Link to="/elektromos-futes-elektromos-autotolto/">Elektromos fűtés, Elektromos autótöltő</Link>
                <Link to="/munkaink">Munkáink</Link> */}
            </div>
          </div>
         


          <p>copyright, made with ❤️ by machacs-website.com</p>
        </footer>
    </Layout>
}