import * as React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export interface IProps {
    description?: string,
    lang?: string,
    meta?: string,
    title?: string,
    image?: string,
    path?: string
}

const SEO = (site: IProps) => {
    const data = useStaticQuery(graphql`
      {
        site {
          siteMetadata {
            title
            description
            baseUrl
          }
        }
      }
    `);

    const defaults = data.site.siteMetadata;
  
    if (defaults.baseUrl === '' && typeof window !== 'undefined') {
      defaults.baseUrl = window.location.origin;
    }
  
    if (defaults.baseUrl === '') {
      console.error('Please set a baseUrl in your site metadata!');
      return null;
    }
  
    const title = site.title ? site.title + ' - ' + defaults.title : defaults.title;
    const description = site.description || defaults.description;
    const url = site.path || defaults.baseUrl;
    const image = site.image && site.image || defaults.baseUrl;
 
    return (
      <Helmet> 

        <html lang="hu"/>
        <title>{title}</title>
        <link rel="canonical" href={url} />
        <meta name="description" content={description} />
        {image && <meta name="image" content={image} />}
  
        <meta property="og:url" content={url} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        {image && <meta property="og:image" content={image} />}

      </Helmet>
    );
  };
 
 export default SEO