import classNames from 'classnames';
import * as React from 'react'
import * as layoutStyles from './layout.module.scss'

  export interface IProps {
    children: React.ReactNode,
    additionalClasses?: string[];
    additionalBackgroundClasses?: string;
    id?: string;
    fullWidthBackground?: Boolean;
    additionalStyle?: React.CSSProperties | undefined;
  }

export default function Layout({ children, additionalClasses, additionalBackgroundClasses, id, fullWidthBackground, additionalStyle }: IProps): JSX.Element {
  const backgroundClasses = classNames(layoutStyles.simpleOrangeBackground, additionalBackgroundClasses);
  const className = classNames(layoutStyles.container, additionalClasses);

  return (
    fullWidthBackground ? 
      <div 
        className={backgroundClasses} 
        style={additionalStyle}
      >
        <div id={id} className={className}>
          {children}
        </div>
      </div>
    :
      <div 
        id={id} 
        className={className} 
        style={additionalStyle}>
        {children}
      </div>
  )
}