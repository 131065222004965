import * as React from 'react'
import Button from '../button'
import Layout from '../layout'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'
import * as headerStyles from './header.module.scss'
import classNames from 'classnames'

interface IProps {
    pageTitle: string;
    pageDescription: string[];
    fullSizeHeader?: boolean;
    image: any;
}

const Header = ({ pageTitle, pageDescription, fullSizeHeader = true, image }: IProps) => {
    const scrollToContact = () => {
        const contactElem = document.getElementById("staticContact");
        contactElem!.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
      };
      
      const mailTo = () => {
        window.location.href = "mailto:machacsdavidaron@gmail.com?subject=Érdeklődés";
      };


    return <>
            <header className={classNames(headerStyles.header, fullSizeHeader && headerStyles.fullSizeHeader)} >
                <div className={headerStyles.headerBlueEffect}>
                    <div className='background-image-container'>
                        <GatsbyImage 
                            className={classNames(headerStyles.staticHeaderImage)} 
                            key={image.id}
                            image={getImage(image)!} alt={'dawatt villanyszerelés piliscsaba, pilisvörösvár, solymár, üröm, pest megye'} 
                        />
                    </div>
                </div>

                <Layout additionalClasses={headerStyles.container}>
                    <div className={headerStyles.titleWrapper}><h1>{pageTitle}</h1></div>
                    <div className={headerStyles.locationWrapper}>
                        
                            {
                                pageDescription.length > 1 ?
                                <ul>
                                    {pageDescription.map((el, index) => <li key={index}> {el} </li> )}
                                </ul>
                                :
                                <h2>
                                    {pageDescription[0]}
                                </h2>
                            }
                    </div>
                    <Button onClick={mailTo}>Kapcsolatfelvétel</Button>
                </Layout>
            </header>
    </>
}

export default Header