import classNames from 'classnames';
import * as React from 'react'
import { MouseEventHandler } from 'react';
export interface IProps {
    children: React.ReactNode,
    onClick?: MouseEventHandler<any> | undefined;
    type?: 'submit' | 'reset' | 'button' | undefined;
    additionalClasses?: string[];
}

export default function Button({ children, onClick, type, additionalClasses }: IProps): JSX.Element {
  let className = classNames(additionalClasses);

  return <button onClick={onClick} type={type} className={className}>{children}</button>
}