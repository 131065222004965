import * as React from 'react'
import CompanyLogo from './company-logo'
import { useEffect } from 'react'
import { Link } from 'gatsby'
import * as navbarStyles from './navbar.module.scss'

function NavBar() {
    useEffect(() => {
      document.addEventListener('scroll', _ => { 
        // document.getElementById('menu')!.style.setProperty('--v-adjust', window.scrollY + 'px');
        
        const movedFromTop = window.innerWidth > 576 ? 50 : 10;

        if (document.body.scrollTop > movedFromTop || document.documentElement.scrollTop > movedFromTop) {
          document.getElementById("navbar")?.classList.add(navbarStyles.navbarContainerOnScroll);
        } else {
          document.getElementById("navbar")?.classList.remove(navbarStyles.navbarContainerOnScroll);
        }
      })
    });

    const scrollToContact = () => {
      const contactElem = document.getElementById("staticContact");
      contactElem!.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
    };

    return <>
    <div id="navbar" className={navbarStyles.navbarContainer}>
      <div className={navbarStyles.navbarWrapper}>
        {/* <div id="navbar-mobile-background" className='navbar-container-background'></div> */}
        <CompanyLogo/>
        <nav id="menu" className={navbarStyles.menu}>
              {/* <div> */}
                {/* <Link to='/munkaink/'  className={navbarStyles.menuOurJobs} >Munkáink</Link> */}
              {/* </div> */}
              <div>
                <a href='https://www.facebook.com/profile.php?id=100082717953603' className={navbarStyles.menuOurJobs} target="_blank" rel="noopener noreferrer">Munkáink</a>
              </div>
              <div>
                <span onClick={scrollToContact} /* className={navbarStyles.menuContact}*/ >Kapcsolat</span>
              </div>
          </nav>
      </div>


    </div>

    </>
}

export default NavBar