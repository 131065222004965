import * as React from 'react'
import * as aboutStyles from './about.module.scss'
import Layout from '../layout';
import classNames from 'classnames';


export default function About(): JSX.Element {
  return <Layout fullWidthBackground={true} additionalBackgroundClasses={aboutStyles.simpleOrangeBackgroundAboutMargin} additionalClasses={aboutStyles.aboutContainer}>
        <article className={aboutStyles.article}>
            <p>
                A Dawatt Electrics Kft. átfogó módon kínál megoldást az elektromos árammal kapcsolatban a tervezéstől a megvalósulásig. Fő tevékenységünk a lakossági és ipari villanyszerelés a hozzá szükséges szoftverek folyamatos fejlesztése, automatizálás és karbantartás. Az elmúlt években dinamikusan fejlődő, megfelelő jogosultságokkal rendelkező kivitelezői és mérnöki csapat vagyunk.
            </p>
        </article>

        {/* <div className={classNames(aboutStyles.aboutSectionImageDesktopWrapper, 'image-border-wrapper')}>
            <div className={aboutStyles.aboutSectionImageDesktopWrapperInside}>
                <StaticImage
                    src="../../assets/images/about-me-image.jpg" 
                    alt="A dinosaur"
                    layout='constrained'
                    width={400}
                    height={300}
                    formats={['webp']}
                    placeholder={'dominantColor'}
                    objectFit={'cover'}
                    transformOptions={{fit: "cover", cropFocus: "center"}}
                />
            </div>
        </div> */}

        <article className={aboutStyles.article}>
            <p>
                Vállalkozásunk elsősorban Pest megyében, Komárom-Esztergom megyében és Budapesten vállal szabvány szerinti kivitelezéssel, különféle elektromos és gyengeáramú szereléseket igénylő munkát. A családi házaktól kezdve - ipari hálózatokon át, - az elektromos ipar teljes skáláját magában foglalva, beleértve az automatizált rendszereket, különböző intelligens házakat, azok programozását és optimalizálását.
            </p>
        </article>

        <article className={aboutStyles.article}>
            <p>
                Fokozott érzékenységgel tekintünk munkáinkra, tudjuk, hogy a hosszú távú sikeres és fenntartható fejlődés csak akkor biztosítható, ha partnerünk elégedett az együttműködéssel. Tevékenységünk során minden résztvevő igényére alapértékként tekintünk, szükségleteit maximálisan figyelembe vesszük.
            </p>
        </article>
    </Layout>
}