import * as React from 'react'
import { Link } from 'gatsby';
import * as companyLogoStyles from './company-logo.module.scss'
import classNames from 'classnames';


export default function CompanyLogo(): JSX.Element {
    return (
      <Link to='/'>
        <div
          className={classNames(companyLogoStyles.companyLogo, 'company-logo')}>
        </div>
      </Link>
    )
  }